import React, {useCallback, useState} from 'react';

import {Box, Grid} from '@mui/material';

import LoadingBox from '../../../base/components/LoadingBox';
import {IntegrationService} from '../../../base/types';
import {MAX_NUMBER_INSTALLED_INTEGRATIONS} from '../constants';
import {IframeIntegration, IframeIntegrationIdTemplate, IframeIntegrationSource} from '../types';
import IframeIntegrationPreparedItem from './IframeIntegrationPreparedItem';

export interface IframeIntegrationsProps {
  integrations: IframeIntegration[];
  isCILoading: boolean;
  isDeleteLoading: boolean;
  isInstallationLoading: boolean;
  sendInstallIntegration: Function;
  allowedIntegrationList: IframeIntegrationSource[];
  sendUninstallIntegration: (integrationId: string) => unknown;
}

function IframeIntegrationsPreparedList(props: IframeIntegrationsProps) {
  const {
    integrations,
    allowedIntegrationList,
    isCILoading,
    isDeleteLoading,
    isInstallationLoading,
    sendInstallIntegration,
    sendUninstallIntegration,
  } = props;
  const [currentTemplateId, setCurrentTemplateId] = useState<IframeIntegrationIdTemplate>(
    IframeIntegrationIdTemplate.UNDEFINED,
  );
  const handleIntegration = useCallback(
    (templateId: IframeIntegrationIdTemplate, integrationId: string) => {
      const foundPreparedIntegration = allowedIntegrationList.find(({config}) => config.templateId === templateId);

      if (!foundPreparedIntegration) {
        console.warn(`An Integration (${templateId}) not registered`);

        return null;
      }

      setCurrentTemplateId(templateId);

      if (integrationId) {
        sendUninstallIntegration(integrationId);
      } else {
        sendInstallIntegration({service: IntegrationService.IFRAME_INTEGRATION, data: foundPreparedIntegration.config});
      }

      return null;
    },
    [sendInstallIntegration, sendUninstallIntegration, allowedIntegrationList],
  );

  const getIntegrationIdByTemplateId = useCallback(
    (templateId: IframeIntegrationIdTemplate): string => {
      const found: IframeIntegration | undefined = integrations.find(
        ({data: {templateId: _templateId}}: IframeIntegration) => templateId === _templateId,
      );

      if (found) {
        return found.integrationId;
      }

      return '';
    },
    [integrations],
  );

  if (isCILoading) {
    return (
      <Box sx={{justifyContent: 'center'}}>
        <LoadingBox />
      </Box>
    );
  }

  return (
    <Grid container>
      {allowedIntegrationList.map((integration) => {
        const {
          config,
          config: {templateId},
          properties: {Icon},
        } = integration;

        return (
          <IframeIntegrationPreparedItem
            key={templateId}
            source={config}
            isMaxInstalledIntegrations={integrations.length >= MAX_NUMBER_INSTALLED_INTEGRATIONS}
            isLoading={isInstallationLoading || isDeleteLoading || isCILoading}
            currentTemplateId={currentTemplateId}
            handleIntegration={handleIntegration}
            integrationId={getIntegrationIdByTemplateId(templateId as IframeIntegrationIdTemplate)}
            icon={<Icon height="25px" width="25px" />}
          />
        );
      })}
    </Grid>
  );
}

export default IframeIntegrationsPreparedList;
