import React from 'react';

import IntegrationCardLayout from '../../../base/components/IntegrationCardLayout';
import VoiceBotsIcon from '../../../base/icons/VoiceBotsIcon';
import {IntegrationStatusType} from '../../../base/types';
import {VOICEBOTS_SHORT_DESCRIPTION, VOICEBOTS_TITLE} from '../../../texts';
import {VoiceBotsPath} from '../constants';

export default function VoiceBotsCard() {
  return (
    <IntegrationCardLayout
      icon={<VoiceBotsIcon width={50} height={50} />}
      title={VOICEBOTS_TITLE}
      description={VOICEBOTS_SHORT_DESCRIPTION}
      href={VoiceBotsPath.base}
      status={IntegrationStatusType.HIDDEN}
      ribbon="Preview"
    />
  );
}
