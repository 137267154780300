import React, {useCallback, useRef, useState} from 'react';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Box, Button, Grid, Tab} from '@mui/material';

import ErrorDialog, {ErrorDialogApi} from '../../../base/components/error/ErrorDialog';
import IntegrationDeleteDialog from '../../../base/components/IntegrationDeleteDialog';
import getIntegrationsByService from '../../../base/helpers/getIntegrationsByService';
import {IntegrationService} from '../../../base/types';
import {IFRAME_INTEGRATION_TITLE} from '../../../texts';
import useConsoleIntegrationDeleteMutation from '../../hooks/useConsoleIntegrationDeleteMutation';
import useConsoleIntegrations from '../../hooks/useConsoleIntegrations';
import useIntegrationInstallationMutation from '../../hooks/useIntegrationInstallationMutation';
import {
  DEFAULT_TEMPLATE_FOR_CUSTOM_INTEGRATION,
  MAX_NUMBER_INSTALLED_INTEGRATIONS,
  WARNING_MESSAGE_TIME_DELAY_FOR_HIDE,
} from '../constants';
import getAllowedIframeIntegrations from '../helpers/getAllowedIframeIntegrations';
import {IframeIntegration} from '../types';
import IframeIntegrationAddDialog from './IframeIntegrationAddDialog';
import IframeIntegrationsInstalledList from './IframeIntegrationsInstalledList';
import IframeIntegrationsPreparedList from './IframeIntegrationsPreparedList';

function IframeIntegrationWrapper() {
  const errorDialogRef = useRef<ErrorDialogApi>();
  const addDialogRef = useRef<any>();
  const {isLoading: isCILoading, data: {installed = [], allowed = []} = {}} = useConsoleIntegrations();
  const [integrationId, setIntegrationId] = useState<string>('');
  const [defaultTab, setDefaultTab] = useState<string>('1');
  const integrations = getIntegrationsByService(
    installed,
    IntegrationService.IFRAME_INTEGRATION,
  ) as IframeIntegration[];
  const {isLoading: isDeleteLoading, mutate: sendUninstallIntegration} = useConsoleIntegrationDeleteMutation({
    onSuccess: (response: any) => {
      const {success, message} = response;

      if (!success) {
        errorDialogRef.current?.show(message);
        console.warn(`[${integrationId}] Unable to process integration delete request`, response);
      }
    },
  });
  const {isLoading: isChangePositionLoading, mutate: sendChangePosition} = useIntegrationInstallationMutation({});
  const removeDialogRef = useRef<any>();

  const onDeleteClick = useCallback((_integrationId: string) => {
    setIntegrationId(() => _integrationId);
    removeDialogRef.current.open();
  }, []);

  const onDeleteConfirmClick = useCallback(() => {
    sendUninstallIntegration(integrationId);
  }, [sendUninstallIntegration, integrationId]);

  const handleChange = (event: React.SyntheticEvent, newTab: string) => {
    setDefaultTab(newTab);
  };

  const onAddClick = useCallback(() => {
    addDialogRef.current.open();
  }, []);

  const [warningMessage, setWarningMessage] = useState<string>('');
  const {isLoading: isInstallationLoading, mutate: sendInstallIntegration} = useIntegrationInstallationMutation({
    onSuccess: (response) => {
      const {success, result, message} = response;

      if (!success) {
        setWarningMessage(result || message);
        setTimeout(() => {
          setWarningMessage('');
        }, WARNING_MESSAGE_TIME_DELAY_FOR_HIDE * 1000);
      } else {
        addDialogRef.current.close();
        setWarningMessage('');
      }
    },
  });

  return (
    <Grid sx={{paddingTop: 0, marginTop: 2, background: '#fdfdfd'}}>
      <TabContext value={defaultTab}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <TabList onChange={handleChange} aria-label="Iframe integrations">
            <Tab label="Manage Integrations" value="1" />
            <Tab label="Ready integrations" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <IframeIntegrationsInstalledList
            isCILoading={isCILoading}
            isChangePositionLoading={isChangePositionLoading}
            isDeleteLoading={isDeleteLoading}
            isInstallationLoading={isInstallationLoading}
            sendChangePosition={sendChangePosition}
            sendInstallIntegration={sendInstallIntegration}
            integrations={integrations}
            onDeleteClick={onDeleteClick}
          />
          {integrations.length < MAX_NUMBER_INSTALLED_INTEGRATIONS ? (
            <Button onClick={onAddClick}>Add integration</Button>
          ) : (
            <Box>The limit of established integrations has been reached</Box>
          )}
        </TabPanel>
        <TabPanel sx={{padding: '10px 0 10px'}} value="2">
          <IframeIntegrationsPreparedList
            sendInstallIntegration={sendInstallIntegration}
            integrations={integrations}
            allowedIntegrationList={getAllowedIframeIntegrations(allowed)}
            isCILoading={isCILoading}
            isDeleteLoading={isDeleteLoading}
            isInstallationLoading={isInstallationLoading}
            sendUninstallIntegration={sendUninstallIntegration}
          />
        </TabPanel>
      </TabContext>
      <Grid sx={{p: 0, marginTop: 3}}>
        <IframeIntegrationAddDialog
          apiRef={addDialogRef}
          isCILoading={isCILoading}
          integrationLength={integrations.length}
          isInstallationLoading={isInstallationLoading}
          sendInstallIntegration={sendInstallIntegration}
          integration={DEFAULT_TEMPLATE_FOR_CUSTOM_INTEGRATION}
          warningMessage={warningMessage}
        />
      </Grid>
      <IntegrationDeleteDialog
        apiRef={removeDialogRef}
        title={IFRAME_INTEGRATION_TITLE}
        onDeleteClick={onDeleteConfirmClick}
      />
      <ErrorDialog apiRef={errorDialogRef} />
    </Grid>
  );
}

export default IframeIntegrationWrapper;
