import React from 'react';

import IntegrationCard from '../../../base/components/IntegrationCard';
import GoogleCalendarIcon from '../../../base/icons/GoogleCalendarIcon';
import {IntegrationService} from '../../../base/types';
import {GOOGLE_CALENDAR_SHORT_DESCRIPTION, GOOGLE_CALENDAR_TITLE} from '../../../texts';
import {GoogleIntegrationPath} from '../types';

export default function GoogleIntegrationCard(props: any) {
  const {integrations} = props;

  return (
    <IntegrationCard
      icon={<GoogleCalendarIcon width={50} height={50} />}
      title={GOOGLE_CALENDAR_TITLE}
      description={GOOGLE_CALENDAR_SHORT_DESCRIPTION}
      href={GoogleIntegrationPath.base}
      integrations={integrations}
      service={IntegrationService.GOOGLE_CALENDAR}
    />
  );
}
