import React from 'react';

import IntegrationCard from '../../../base/components/IntegrationCard';
import MicrosoftTeamsPresenceIcon from '../../../base/icons/MicrosoftTeamsPresenceIcon';
import {Integration, IntegrationService} from '../../../base/types';
import {MICROSOFT_PRESENCE_SHORT_DESCRIPTION, MICROSOFT_PRESENCE_TITLE} from '../../../texts';
import {MicrosoftPresenceIntegrationPath} from '../types';

interface MicrosoftIntegrationCardProps {
  integrations: Integration[];
}

export default function MicrosoftPresenceIntegrationCard({integrations}: MicrosoftIntegrationCardProps) {
  return (
    <IntegrationCard
      icon={<MicrosoftTeamsPresenceIcon width={50} height={50} />}
      title={MICROSOFT_PRESENCE_TITLE}
      description={MICROSOFT_PRESENCE_SHORT_DESCRIPTION}
      href={MicrosoftPresenceIntegrationPath.base}
      integrations={integrations}
      service={IntegrationService.MICROSOFT_PRESENCE}
    />
  );
}
