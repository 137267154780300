import React from 'react';
import {Link} from 'react-router-dom';

import {Card, CardActionArea, CardActions, CardContent, styled, Typography} from '@mui/material';
import Box from '@mui/material/Box';

import {IntegrationStatusType} from '../types';
import IntegrationCardStatus from './IntegrationCardStatus';

const ApplicationChip = styled('div')({
  background: '#164C7414',
  fontSize: '12px',
  borderRadius: '6px',
  padding: '4px 8px',
  display: 'inline',
});

const Ribbon = styled(Box)({
  width: '110px',
  height: '110px',
  overflow: 'hidden',
  position: 'absolute',
  top: '-20px',
  right: '-40px',

  '& span': {
    position: 'absolute',
    display: 'block',
    width: '140px',
    padding: '4px 0',
    backgroundColor: '#35DBA8',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '12px',
    fontFamily: 'monospace',

    left: '-25px',
    top: '30px',
    transform: 'rotate(45deg)',
  },

  '&::before, &::after': {
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    content: '""',
    position: 'absolute', // Necessary for pseudo-elements
  },

  '&::before': {
    top: '0',
    left: '0',
  },

  '&::after': {
    bottom: '0',
    right: '0',
  },
});

interface IntegrationCardLayoutProps {
  icon: React.ReactNode;
  status: IntegrationStatusType | null;
  href: string;
  title: string;
  description: string;
  ribbon?: string;
}

export default function IntegrationCardLayout(props: IntegrationCardLayoutProps) {
  const {icon, status, href, title, description, ribbon} = props;

  return (
    <Card sx={{minWidth: 268, maxWidth: 268, m: 2, textAlign: 'left'}}>
      <CardActionArea
        component={Link}
        to={href}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: '100%',
          justifyContent: 'stretch',
        }}>
        <CardContent sx={{flex: 1}}>
          {ribbon && (
            <Ribbon>
              <span>{ribbon}</span>
            </Ribbon>
          )}
          {icon}
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" mt={1}>
            {description}
          </Typography>
        </CardContent>
        {status !== IntegrationStatusType.HIDDEN && (
          <CardActions sx={{pt: 1, pl: 2, pb: 1, pr: 2}}>
            <IntegrationCardStatus status={status} />
          </CardActions>
        )}
      </CardActionArea>
    </Card>
  );
}
