import React from 'react';

import IntegrationCard from '../../../base/components/IntegrationCard';
import GongIcon from '../../../base/icons/GongIcon';
import {IntegrationService} from '../../../base/types';
import {GONG_SHORT_DESCRIPTION, GONG_TITLE} from '../../../texts';
import {GongIntegrationPath} from '../types';

export default function GongIntegrationCard(props: any) {
  const {integrations} = props;

  return (
    <IntegrationCard
      icon={<GongIcon width={50} height={50} />}
      title={GONG_TITLE}
      description={GONG_SHORT_DESCRIPTION}
      href={GongIntegrationPath.base}
      integrations={integrations}
      service={IntegrationService.GONG}
    />
  );
}
