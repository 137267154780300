import React, {useCallback, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import {IntegrationService} from '../../../base/types';
import getRulesValidation from '../helpers/getRulesValidation';
import {AvailablePlatforms, IframeData, IframeIntegration} from '../types';
import EmailFormListBuilder from './EmailFormListBuilder';
import TooltipInfo from './TooltipInfo';

export type IframeIntegrationsFromProps = {
  integration: IframeIntegration;
  isInstallationLoading: boolean;
  isPreparedIntegration: boolean;
  sendInstallIntegration: Function;
  onDeleteClick?: Function;
  isDeleteLoading?: boolean;
  warningMessage?: string;
  isCILoading?: boolean;
  onDialogClose?: Function;
};

function IframeIntegrationItemForm(props: IframeIntegrationsFromProps) {
  const {
    onDeleteClick,
    isDeleteLoading,
    warningMessage,
    sendInstallIntegration,
    isInstallationLoading,
    integration: {
      integrationId,
      data: {
        isNew,
        title,
        iframeUrl,
        description,
        usePbxToken,
        visibleInUi,
        position,
        isMandatoryToUse,
        platforms,
        accessEmails,
        deniedEmails,
        isPreparedIntegration,
        templateId,
      },
    },
  } = props;

  const {register, handleSubmit, watch, setValue} = useForm({
    defaultValues: {
      title,
      iframeUrl,
      description,
      visibleInUi,
      usePbxToken,
      position,
      isMandatoryToUse,
      platforms,
      accessEmails,
      deniedEmails,
      isPreparedIntegration,
      templateId,
    },
    mode: 'onChange',
  });

  setValue('position', position);

  const [errors, setError] = useState<any>({});
  const refAccessEmails = useRef<string[]>(accessEmails);
  const refDeniedEmails = useRef<string[]>(deniedEmails);
  const onSubmit = useCallback(
    (iframeData: IframeData) => {
      const data = {
        ...iframeData,
        isNew,
        integrationId,
        accessEmails: refAccessEmails.current,
        deniedEmails: refDeniedEmails.current,
      };
      sendInstallIntegration({service: IntegrationService.IFRAME_INTEGRATION, data});
    },
    [sendInstallIntegration, isNew, integrationId],
  );

  const validateField = useCallback(
    (name: string, value: string) => {
      const status = getRulesValidation(name, value);

      setError({...errors, [name]: !status});

      return status;
    },
    [errors],
  );

  const showMessage = useCallback(() => {
    if (warningMessage) {
      return <Alert sx={{m: '20px 0 0 0'}} severity="warning" children={<div>{warningMessage}</div>} />;
    }
  }, [warningMessage]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item container direction="column" sx={{p: 2, paddingTop: 0, paddingBottom: 0}} rowSpacing={2}>
        <TextField sx={{display: 'none'}} type="hidden" {...register('position')} />
        <TextField sx={{display: 'none'}} type="hidden" {...register('templateId')} />
        <TextField sx={{display: 'none'}} type="hidden" {...register('isPreparedIntegration')} />
        <TextField
          required
          placeholder="maximum of 25 characters"
          error={errors.title ? true : false}
          {...register('title', {validate: (value) => validateField('title', value)})}
          size="small"
          label="Title"
          sx={{marginTop: 2}}
        />
        {!isPreparedIntegration ? (
          <TextField
            required
            placeholder="https://{domain}"
            error={errors.iframeUrl ? true : false}
            {...register('iframeUrl', {validate: (value) => validateField('iframeUrl', value)})}
            size="small"
            label="Iframe url"
            sx={{marginTop: 2}}
            inputProps={{maxLength: 1500}}
          />
        ) : null}
        <TextField
          error={errors.description ? true : false}
          {...register('description', {validate: (value) => validateField('description', value)})}
          multiline
          disabled={isPreparedIntegration}
          rows={4}
          size="small"
          label="Description"
          sx={{marginTop: 2}}
          inputProps={{maxLength: 500}}
        />
        <Box>
          <FormControlLabel
            {...register('usePbxToken')}
            sx={{marginTop: 1}}
            control={<Checkbox />}
            label="Use x-bees authorization token"
            labelPlacement="end"
            checked={watch('usePbxToken')}
          />
          <TooltipInfo title="Enable to use x-bees authorization token for secure communication with the integration." />
        </Box>
        <Box>
          <FormControlLabel
            {...register('visibleInUi')}
            sx={{marginTop: 1}}
            control={<Checkbox />}
            label="Display on the Info frame"
            checked={watch('visibleInUi')}
          />
          <TooltipInfo title="Choose whether the iframe should be visible within the user interface." />
        </Box>
        <Box>
          <FormControlLabel
            {...register('isMandatoryToUse')}
            sx={{marginTop: 1}}
            control={<Checkbox />}
            label="Notify users if authorization is required"
            checked={watch('isMandatoryToUse')}
          />
          <TooltipInfo title="In case the integration requires authorization, the relevant notification is displayed to users, e.g. when Info frame is opened or search is used." />
        </Box>
        <Box sx={{mt: 1}}>
          <FormControl variant="filled" sx={{m: 0, minWidth: 200}}>
            <InputLabel sx={{left: '-12px', top: '-8px', color: 'rgba(0, 0, 0, 0.87)'}}>
              Available on platforms
            </InputLabel>
          </FormControl>
          <Select
            {...register('platforms')}
            variant="standard"
            value={watch('platforms')}
            defaultValue={AvailablePlatforms.ALL}
            label="Available on platforms"
            onChange={({target: {value}}) => {
              setValue('platforms', value);
            }}>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="web">Web</MenuItem>
            <MenuItem value="mobile">Mobile</MenuItem>
          </Select>
        </Box>
        <Box sx={{marginTop: 4, zIndex: 3}}>
          <EmailFormListBuilder
            arrayEmails={refAccessEmails}
            setValue={setValue}
            fieldName="accessEmails"
            title="Make integration available only to"
            info="By default, the integration is available to all users. To make the integration available to a limited number of users, enter their emails here. You can enter multiple emails separated by commas."
          />
        </Box>
        <Box sx={{marginTop: 5, zIndex: 2}}>
          <EmailFormListBuilder
            arrayEmails={refDeniedEmails}
            setValue={setValue}
            fieldName="deniedEmails"
            title="Block user access"
            info="Enter emails of users who should not have access to the integration. You can enter multiple emails separated by commas."
          />
        </Box>
        {showMessage()}
        <Grid
          direction="row"
          sx={{marginTop: 6, marginBottom: 2}}
          alignItems="baseline"
          justifyContent="start"
          container>
          <Grid sx={{marginRight: 1}}>
            <LoadingButton
              disabled={isDeleteLoading}
              loading={isInstallationLoading}
              type="submit"
              variant="contained"
              size="small">
              {isNew ? 'Install' : 'Save'}
            </LoadingButton>
          </Grid>
          {!isNew ? (
            <Grid>
              <LoadingButton
                variant="outlined"
                color="error"
                startIcon={<DeleteOutlineIcon />}
                size="small"
                onClick={() => (onDeleteClick as Function)(integrationId)}
                loading={isDeleteLoading}>
                Delete
              </LoadingButton>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
}

export default IframeIntegrationItemForm;
