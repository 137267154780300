export default function IframeIntegrationIcon(props: any) {
  return (
    <svg
      focusable="false"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_2249_1239)">
        <path
          d="M27.5 0H26.25V5H25C23.6193 5 22.5 6.11929 22.5 7.5C22.5 8.88071 23.6193 10 25 10H26.25V13.75H31.25V15C31.25 16.3807 32.3693 17.5 33.75 17.5C35.1307 17.5 36.25 16.3807 36.25 15V13.75H40V12.4999C40 5.59639 34.4036 0 27.5 0Z"
          fill="#35A4FA"
        />
        <path
          d="M17.5 22.5C17.5 20.7446 17.5 16.5092 17.5 15H16.25C14.8692 15 13.75 13.8808 13.75 12.5C13.75 11.12 14.8699 10 16.25 10H17.5V5C7.83498 5 0 12.835 0 22.5L8.74996 24.4706L17.5 22.5Z"
          fill="#EE4141"
        />
        <path
          d="M28.75 22.5V23.75C28.75 25.1308 27.6308 26.25 26.25 26.25C24.8692 26.25 23.75 25.1308 23.75 23.75V22.5H17.4999L15.3726 31.25L17.5 40C27.165 40 35 32.1649 35 22.5H28.75Z"
          fill="#EF6C00"
        />
        <path
          d="M17.5 22.5H11.25V21.2499C11.25 19.8699 10.13 18.7499 8.74996 18.7499C7.36918 18.7499 6.24996 19.8692 6.24996 21.2499V22.5H0C0 32.1649 7.83498 40 17.5 40V33.7499L18.9804 31.25L17.5 28.75V22.5Z"
          fill="#FACC2A"
        />
        <path
          d="M18.75 28.7499H17.5V33.7499H18.75C20.1308 33.7499 21.25 32.6307 21.25 31.2499C21.2501 29.87 20.1301 28.7499 18.75 28.7499Z"
          fill="#FACC2A"
        />
      </g>
      <defs>
        <clipPath id="clip0_2249_1239">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
