import React from 'react';

import IntegrationCard from '../../../base/components/IntegrationCard';
import HubspotBackendIcon from '../../../base/icons/HubspotBackendIcon';
import {IntegrationService} from '../../../base/types';
import {HUBSPOT_BACKEND_SHORT_DESCRIPTION, HUBSPOT_BACKEND_TITLE} from '../../../texts';
import {HubspotBackendIntegrationPath} from '../types';

export default function HubspotBackendIntegrationCard(props: any) {
  const {integrations} = props;

  return (
    <IntegrationCard
      icon={<HubspotBackendIcon width={50} height={50} />}
      title={HUBSPOT_BACKEND_TITLE}
      description={HUBSPOT_BACKEND_SHORT_DESCRIPTION}
      href={HubspotBackendIntegrationPath.base}
      integrations={integrations}
      service={IntegrationService.HUBSPOT_BACKEND}
    />
  );
}
