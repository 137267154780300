import React, {useCallback} from 'react';

import IntegrationScreen from '../../../base/components/IntegrationScreen';
import findIntegrationByService from '../../../base/helpers/findIntegrationByService';
import SalesforceBackendIcon from '../../../base/icons/SalesforceBackendIcon';
import {IntegrationService} from '../../../base/types';
import {
  SALESFORCE_TELEPHONY_DESCRIPTION,
  SALESFORCE_TELEPHONY_SHORT_DESCRIPTION,
  SALESFORCE_TELEPHONY_TITLE,
} from '../../../texts';
import useConsoleIntegrations from '../../hooks/useConsoleIntegrations';
import useIntegrationInstallationMutation from '../../hooks/useIntegrationInstallationMutation';

export default function SalesforceTelephonyIntegrationScreen() {
  const service = IntegrationService.SALESFORCE_TELEPHONY;
  const {data: {installed = []} = {}} = useConsoleIntegrations();
  const integration = findIntegrationByService(installed, service);
  const {isLoading: isInstallationLoading, mutate} = useIntegrationInstallationMutation({});
  const onInstallClick = useCallback(() => {
    mutate({service, data: {integrationName: IntegrationService.SALESFORCE_TELEPHONY}});
  }, [mutate, service]);

  return (
    <IntegrationScreen
      title={SALESFORCE_TELEPHONY_TITLE}
      description={SALESFORCE_TELEPHONY_SHORT_DESCRIPTION}
      info={
        <>
          <br />
          {SALESFORCE_TELEPHONY_DESCRIPTION}
          <br />
          <br />
          <a rel="noreferrer" target="_blank" href="https://wildix.atlassian.net/wiki/x/VgvOAQ">
            Salesforce Lightning integration
          </a>
          <br />
          <a rel="noreferrer" target="_blank" href="https://wildix.atlassian.net/wiki/x/AArOAQ">
            Salesforce Classic integration
          </a>
          <br />
          <a
            rel="noreferrer"
            target="_blank"
            href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000FeFugUAF&tab">
            Wildix for Salesforce on AppExchange
          </a>
        </>
      }
      icon={<SalesforceBackendIcon width={96} height={96} />}
      integration={integration}
      onInstallClick={onInstallClick}
      isInstallLoading={isInstallationLoading}
    />
  );
}
