import {ReactNode} from 'react';

import {Integration, IntegrationService} from '../../base/types';

export enum IframeIntegrationPath {
  base = '/iframe_integration',
  install = '/iframe_integration/install',
}

export enum IframeIntegrationIdTemplate {
  HUBSPOT = 'HUBSPOT',
  SALESFORCE = 'SALESFORCE',
  MICROSOFT = 'MICROSOFT',
  GOOGLE = 'GOOGLE',
  UNDEFINED = '',
}

export enum AvailablePlatforms {
  ALL = 'all',
  WEB = 'web',
  MOBILE = 'mobile',
}

export type IframeData = {
  title: string;
  iframeUrl: string;
  description: string;
  usePbxToken: boolean;
  visibleInUi: boolean;
  templateId: IframeIntegrationIdTemplate | string;
  position?: number;
  isNew?: boolean;
  isMandatoryToUse: boolean;
  platforms: string;
  accessEmails: string[];
  deniedEmails: string[];
  isPreparedIntegration: boolean;
  children?: ReactNode;
};

export type IframeIntegrationSource = {
  config: IframeData;
  properties: {
    Icon: (props: any) => JSX.Element;
  };
};

export type IframeDataPosition = {
  currentIntegrationId: string;
  targetIntegrationId: string;
};

export interface IframeIntegrationInstallProps {
  service: IntegrationService.IFRAME_INTEGRATION;
  data: IframeData | IframeDataPosition;
}

export interface IframeIntegration extends Integration {
  integrationId: string;
  externalId: string;
  templateId: string;
  blendId: string;
  service: IntegrationService;
  data: IframeData;
}

export enum PositionDirection {
  UP = 'up',
  DOWN = 'down',
}
