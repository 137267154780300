import React from 'react';

import IntegrationCard from '../../../base/components/IntegrationCard';
import WebHookIcon from '../../../base/icons/WebHookIcon';
import {IntegrationService} from '../../../base/types';
import {WEBHOOK_SHORT_DESCRIPTION, WEBHOOK_TITLE} from '../../../texts';
import {WebhooksIntegrationPath} from '../constants';

export default function WebHookIntegrationCard(props: any) {
  const {integrations} = props;

  return (
    <IntegrationCard
      icon={<WebHookIcon width={50} height={50} />}
      title={WEBHOOK_TITLE}
      description={WEBHOOK_SHORT_DESCRIPTION}
      href={WebhooksIntegrationPath.base}
      integrations={integrations}
      service={IntegrationService.WEBHOOK}
    />
  );
}
