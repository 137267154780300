import GoogleIcon from '../../base/icons/GoogleIcon';
import HubspotIframeIcon from '../../base/icons/HubspotIframeIcon';
import MicrosoftCalendarIcon from '../../base/icons/MicrosoftCalendarIcon';
import SalesforceIframeIcon from '../../base/icons/SalesforceIframeIcon';
import {IntegrationService} from '../../base/types';
import {GOOGLE_URL, HUBSPOT_URL, MICROSOFT_URL, SALESFORCE_URL} from './env';
import {AvailablePlatforms, IframeIntegrationIdTemplate, IframeIntegrationSource} from './types';

export const DEFAULT_TEMPLATE_FOR_CUSTOM_INTEGRATION = {
  integrationId: '',
  templateId: '',
  service: IntegrationService.IFRAME_INTEGRATION,
  blendId: '',
  externalId: '',
  data: {
    isNew: true,
    title: '',
    iframeUrl: '',
    description: '',
    usePbxToken: false,
    visibleInUi: true,
    position: 0,
    templateId: '',
    isMandatoryToUse: true,
    platforms: AvailablePlatforms.ALL,
    accessEmails: [],
    deniedEmails: [],
    isPreparedIntegration: false,
  },
};

export const MAX_TITLE_FIELD_LENGTH = 30;

export const MAX_DESCRIPTION_FIELD_LENGTH = 500;

export const WARNING_MESSAGE_TIME_DELAY_FOR_HIDE = 5;

export const MAX_NUMBER_INSTALLED_INTEGRATIONS = 10;

const DEFAULT_TEMPLATE = {
  usePbxToken: true,
  visibleInUi: true,
  isNew: true,
  isMandatoryToUse: false,
  platforms: AvailablePlatforms.ALL,
  position: 0,
  accessEmails: [],
  deniedEmails: [],
  isPreparedIntegration: true,
};

const HUBSPOT_TEMPLATE = {
  config: {
    ...DEFAULT_TEMPLATE,
    templateId: IframeIntegrationIdTemplate.HUBSPOT,
    title: 'HubSpot',
    iframeUrl: HUBSPOT_URL,
    description:
      'Allows you to view HubSpot contact date on the Info frame, see HubSpot contacts in Live and Dialpad search and create tasks and new HubSpot contacts. For contact lookup, make sure you enable HubSpot Auto Logging.',
  },
  properties: {
    Icon: HubspotIframeIcon,
  },
};

const SALESFORCE_TEMPLATE = {
  config: {
    ...DEFAULT_TEMPLATE,
    templateId: IframeIntegrationIdTemplate.SALESFORCE,
    title: 'Salesforce',
    iframeUrl: SALESFORCE_URL,
    description:
      'Allows you to view Salesforce lead data on the Info frame, see Salesforce contacts in Live and Dialpad search and create tasks and new Salesforce leads. For contact lookup, make sure you enable Salesforce Auto Logging.',
  },
  properties: {
    Icon: SalesforceIframeIcon,
  },
};

const MICROSOFT_TEMPLATE = {
  config: {
    ...DEFAULT_TEMPLATE,
    templateId: IframeIntegrationIdTemplate.MICROSOFT,
    title: 'Microsoft 365',
    iframeUrl: MICROSOFT_URL,
    description:
      'Allows you to see Microsoft 365 contacts in Live and Dialpad search, as well as view and open the latest emails with the contact via Info frame.',
  },
  properties: {
    Icon: MicrosoftCalendarIcon,
  },
};

const GOOGLE_TEMPLATE = {
  config: {
    ...DEFAULT_TEMPLATE,
    usePbxToken: false,
    isMandatoryToUse: true,
    templateId: IframeIntegrationIdTemplate.GOOGLE,
    title: 'Google Workspace',
    iframeUrl: GOOGLE_URL,
    description:
      'View, add and edit Google Workspace contacts, see ongoing and upcoming calendar events with your contacts, and lookup contacts in Live Search.',
  },
  properties: {
    Icon: GoogleIcon,
  },
};

export const PREFIX_IFRAME_INTEGRATION = 'iframe_';

export const PREPARED_PUBLIC_INTEGRATION: IframeIntegrationSource[] = [
  HUBSPOT_TEMPLATE,
  SALESFORCE_TEMPLATE,
  MICROSOFT_TEMPLATE,
  GOOGLE_TEMPLATE,
];
